.App {
  text-align: center;
  padding-left: calc(100vw - 100%);
    background:  #FFFDFA;
}


.ant-image-preview-root .ant-image-preview-body {
  background: white;
}

.ant-image-preview-root .ant-image-preview-operations {
  background-color: lightgrey;
  opacity: 0.2;
  color: black;
}


.exhibit-card {
    background-color: #f8f3ee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

.exhibit-card:hover {
    cursor: pointer;
    border: 1px grey;
}

.ant-card-bordered {
    border: 2px solid black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.exhibit-card .ant-card-body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
