@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.App {
  text-align: center;
}


body, #root, .App {
  height: 100%;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-card-meta-description {
  text-align: justify;
}

.picture-frame {
  background-color:#ddc;
  border:solid 5vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
  box-sizing:border-box;
  display:inline-block;
  margin:0px;
  padding:20px;
  position:relative;
  text-align:center;
  cursor: pointer;

}

.exhibit-card .picture-frame {
  border:solid 20px #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  padding:10px;
}



/*.picture-frame :after {*/
  /*!*width: 100%;*!*/
  /*border-radius:2px;*/
  /*bottom:-55px;*/
  /*box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);*/
  /*content:"";*/
  /*left:-65px;*/
  /*position:absolute;*/
  /*right:-65px;*/
  /*top:-55px;*/
/*}*/

.picture-frame img {
  border:solid 2px;
  border-bottom-color:#ffe;
  border-left-color:#eed;
  border-right-color:#eed;
  border-top-color:#ccb;
  background-color: white;
  max-height:100%;
  max-width:100%;

}


.exhibit-title {
  font-weight: bold;
  text-align: center;
  color: #333;
  text-shadow: 2px 2px #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 4.5em !important;
  margin-top: 40px;

}
