@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather&display=swap);
.App {
  text-align: center;
  padding-left: calc(100vw - 100%);
    background:  #FFFDFA;
}


.ant-image-preview-root .ant-image-preview-body {
  background: white;
}

.ant-image-preview-root .ant-image-preview-operations {
  background-color: lightgrey;
  opacity: 0.2;
  color: black;
}


.exhibit-card {
    background-color: #f8f3ee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

.exhibit-card:hover {
    cursor: pointer;
    border: 1px grey;
}

.ant-card-bordered {
    border: 2px solid black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.exhibit-card .ant-card-body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App {
  text-align: center;
}


body, #root, .App {
  height: 100%;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-card-meta-description {
  text-align: justify;
}

.picture-frame {
  background-color:#ddc;
  border:solid 5vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
  box-sizing:border-box;
  display:inline-block;
  margin:0px;
  padding:20px;
  position:relative;
  text-align:center;
  cursor: pointer;

}

.exhibit-card .picture-frame {
  border:solid 20px #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  padding:10px;
}



/*.picture-frame :after {*/
  /*!*width: 100%;*!*/
  /*border-radius:2px;*/
  /*bottom:-55px;*/
  /*box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);*/
  /*content:"";*/
  /*left:-65px;*/
  /*position:absolute;*/
  /*right:-65px;*/
  /*top:-55px;*/
/*}*/

.picture-frame img {
  border:solid 2px;
  border-bottom-color:#ffe;
  border-left-color:#eed;
  border-right-color:#eed;
  border-top-color:#ccb;
  background-color: white;
  max-height:100%;
  max-width:100%;

}


.exhibit-title {
  font-weight: bold;
  text-align: center;
  color: #333;
  text-shadow: 2px 2px #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 4.5em !important;
  margin-top: 40px;

}

